<template>
  <div>
    <AddNewCustomer
        v-model='isAddNewCustomer'
        :status-check='statusCheck'
        @alertMessage='alertMessage'
        @onAdd="fetchUsers('add')"
    />
    <EditCustomer
        v-model='isEditCustomer'
        :data-edit='dataEditCustomer'
        :status-check='statusCheck'
        @onUpdate="fetchUsers('update')"
    />
    <!-- list filters -->
    <v-card>
      <v-card-title class='px-2'>
        {{ $t('customer_data') }}
        <span v-if='message' class='text-sm error--text pt-1 ml-2'>({{ message }})</span>
        <v-spacer></v-spacer>
        <div class='d-flex align-center flex-wrap'>
          <v-btn
              color='primary'
              :disabled="customerAddStatus == '0'"
              class='me-3 d-none d-md-block'
              @click='addCustomer'
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span class=''>{{ $t('add_customers') }}</span>
          </v-btn>
          <v-btn
              color='primary'
              class='me-3 d-block d-md-none'
              outlined
              :disabled="customerAddStatus == '0'"
              small
              fab
              @click='addCustomer'
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
          </v-btn>
          <v-btn color='secondary' outlined class='d-none d-md-block' @click='isImportFile = true'>
            <v-icon size='17' class='me-1'>
              {{ icons.mdiFileImportOutline }}
            </v-icon>
            <span>{{ $t('import_files_csv') }}</span>
          </v-btn>
          <v-btn color='secondary' outlined fab small class='d-block d-md-none'
                 @click='isImportFile = true'>
            <v-icon size='17'>
              {{ icons.mdiFileImportOutline }}
            </v-icon>
          </v-btn>
        </div>
      </v-card-title>
      <v-row class='px-2'>
        <v-col cols='12' md='6' lg='3' class='py-0 '>
          <div class="d-flex">
            <v-text-field
                v-model='searchQuery'
                :placeholder="`${$t('wordingSearch')}`"
                :label="$t('inout_three_charector')"
                @keypress.enter='middleWhereFetchUsers'
                outlined
                dense

            >
              <template v-slot:append>
                <div class="v-input__append-inner-customer-search">
                  <v-btn color="primary" style="max-width: 50px" class="rounded-l-0" @click="middleWhereFetchUsers"
                         :disabled="searchQuery.length < 3">
                    <v-icon style="cursor: pointer">{{
                        icons.mdiMagnify
                      }}
                    </v-icon>
                    ค้นหา
                  </v-btn>
                </div>
              </template>
            </v-text-field>
          </div>
        </v-col>

        <!-- customer group filter -->
        <v-col cols='12' md='6' lg='3' class='py-0'>
          <v-autocomplete
              v-model='customer_group_id'
              :placeholder="$t('select_customer_group')"
              :label="$t('group_customers')"
              :items='customerGroupListData'
              :no-data-text="$t('no_information')"
              item-text='customer_group_name'
              item-value='customer_group_id'
              outlined
              dense
          ></v-autocomplete>
        </v-col>

        <!-- customer status filter -->
        <v-col cols='12' md='6' class='py-0' lg='3'>
          <v-select
              v-model='customer_status_id'
              :placeholder="$t('select_customer_status')"
              :label="$t('customer_status')"
              :items='customerStatusListData'
              :item-text='$i18n.locale'
              item-value='value'
              outlined
              dense
          ></v-select>
        </v-col>

        <v-col cols='12' md='6' class='py-0' lg='3'>
          <v-row>
            <v-col cols='12' md='6' class='pb-0'>
              <v-dialog ref='date1' v-model.trim='modal2' :return-value.sync='date_start'
                        width='290px'>
                <template v-slot:activator='{ on, attrs }'>
                  <v-text-field
                      v-model.trim='date_start'
                      :label="$t('date')"
                      outlined
                      dense
                      v-bind='attrs'
                      v-on='on'
                  ></v-text-field>
                </template>
                <v-date-picker v-model.trim='date_start' class='' :locale='$i18n.locale' scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color='primary' @click='$refs.date1.save(date_start)'>
                    {{ $t('confirm') }}
                  </v-btn>
                  <v-btn text color='secondary' @click='modal2 = false'>
                    {{ $t('cancel') }}
                  </v-btn>

                  <v-spacer></v-spacer>
                </v-date-picker>
              </v-dialog>
            </v-col>
            <v-col cols='12' md='6' class='py-0 pt-md-3'>
              <v-dialog ref='date2' v-model.trim='modal' :return-value.sync='date_end'
                        width='290px'>
                <template v-slot:activator='{ on, attrs }'>
                  <v-text-field
                      v-model.trim='date_end'
                      class=''
                      :label="$t('to')"
                      outlined
                      dense
                      v-bind='attrs'
                      v-on='on'
                  ></v-text-field>
                </template>
                <v-date-picker v-model.trim='date_end' class='' :locale='$i18n.locale' scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color='primary' @click='$refs.date2.save(date_end)'>
                    {{ $t('confirm') }}
                  </v-btn>
                  <v-btn text color='secondary' @click='modal = false'>
                    {{ $t('cancel') }}
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-date-picker>
              </v-dialog>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-data-table
          :headers='tableColumns'
          :items='userListTable'
          :options.sync='options'
          :server-items-length='totalUserListTable'
          :footer-props='footer'
          :loading='loading'
          :item-key='customer_id_pri'
          disable-sort
          hide-default-footer
          :loading-text="$t('data_loading')"
          :no-data-text="$t('no_information')"
      >
        <!-- id -->
        <template #[`item.customer_id_pri`]='{ index }'>
          {{ segmentId + index + 1 }}
        </template>

        <template #[`item.customer_image`]='{ item }'>
          <v-avatar
              :color="item.customer_image ? '' : 'primary'"
              :class="item.customer_image ? '' : 'v-avatar-light-bg primary--text'"
              size='32'
          >
            <v-img :src='item.customer_image'></v-img>
          </v-avatar>
        </template>

        <!-- name -->
        <!-- id -->
        <template #[`item.customer_id`]='{ item }'>
          <router-link
              class='font-weight-medium text-decoration-none'
              :to="{ name: 'DetailCustomer', params: { id: item.customer_id_pri } }"
          >
            {{ item.customer_id }}
          </router-link>
        </template>

        <template #[`item.customer_fname`]='{ item }'>
          <span
              v-if="
              item.customer_prefix == '-' ||
              item.customer_prefix == 'null' ||
              item.customer_prefix == null ||
              item.customer_prefix == '' ||
              item.customer_prefix == 'ไม่ระบุ'
            "
          >
            -
          </span>
          <span v-else>{{ item.customer_prefix }}</span>
          {{ item.customer_fname }}
          {{ item.customer_lname }}

          <span v-if="item.customer_nname && item.customer_nname != 'null'">({{
              item.customer_nname
            }})</span>
          <br/>
          <v-chip
              v-for='tag in item.tag'
              :key='tag.customer_tag_id'
              :class="`v-chip-${$vuetify.theme.dark ? 'dark' : 'light'}-bg warning--text me-1`"
              label
              x-small
              color='warning'
          >
            {{ tag.customer_tag_name }}
          </v-chip>
        </template>

        <!-- gender -->
        <template #[`item.customer_gender`]='{ item }'>
          <v-chip
              v-if="item.customer_gender == 'ชาย' || item.customer_gender == 'Male'"
              :class="`v-chip-${$vuetify.theme.dark ? 'dark' : 'light'}-bg primary--text`"
              color='primary'
              small
              label
          >
            <v-icon small>
              {{ icons.mdiHumanMale }}
            </v-icon>
            {{ item.customer_gender }}
          </v-chip>
          <v-chip
              v-else-if="
              item.customer_gender == 'หญิง' || item.customer_gender == 'Female.' || item.customer_gender == 'Female'
            "
              :class="`v-chip-${$vuetify.theme.dark ? 'dark' : 'light'}-bg info--text`"
              color='info'
              small
              label
          >
            <v-icon small>
              {{ icons.mdiHumanFemale }}
            </v-icon>
            {{ item.customer_gender }}
          </v-chip>
          <span v-else> - </span>
        </template>

        <!-- Line -->
        <template v-slot:[`item.line_status_id`]='{ item }'>
          <v-icon v-if='item.line_status_id == 3' color='success'>
            {{ icons.mdiCheck }}
          </v-icon>
          <v-icon v-if='item.line_status_id == 2' color='warning'>
            {{ icons.mdiAlertOutline }}
          </v-icon>
          <v-icon v-if='item.line_status_id == 1' color='error'>
            {{ icons.mdiClose }}
          </v-icon>
        </template>

        <!-- reward point -->
        <template v-slot:[`item.customer_point`]='{ item }'>
          {{ item.customer_point | formatPrice }}
        </template>

        <!-- add On -->
        <template v-slot:[`item.customer_create`]='{ item }'>
          <ConverseDate :date='item.customer_create'/>
        </template>

        <!-- status -->
        <template v-slot:[`item.customer_status_id`]='{ item }'>
          <StatusBlock :status='item.customer_status_id'/>
        </template>

        <!-- actions -->
        <template v-slot:[`item.actions`]='{ item }'>
          <v-tooltip top>
            <template v-slot:activator='{ on, attrs }'>
              <router-link
                  class='font-weight-medium text-decoration-none'
                  :to="{ name: 'DetailCustomer', params: { id: item.customer_id_pri } }"
              >
                <v-btn icon small color='primary' v-bind='attrs' v-on='on'>
                  <v-icon>{{ icons.mdiInformationOutline }}</v-icon>
                </v-btn>
              </router-link>
            </template>
            <span>{{ $t('detail') }}</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator='{ on, attrs }'>
              <v-btn icon small color='primary' v-bind='attrs' @click='editCustomer(item)'
                     v-on='on'>
                <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('edit') }}</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator='{ on, attrs }'>
              <v-btn
                  icon
                  small
                  v-bind='attrs'
                  color='primary'
                  @click="
                  ;(customer_id_pri = item.customer_id_pri),
                    (update_customer_status_id = item.customer_status_id == 1 ? '2' : '1'),
                    (isUpdateStatusCustomer = true)
                "
                  v-on='on'
              >
                <v-icon>{{
                    item.customer_status_id == 1 ? icons.mdiDeleteOutline : icons.mdiCheck
                  }}
                </v-icon>
              </v-btn>
            </template>
            <span>{{ item.customer_status_id == 1 ? $t('suspend') : $t('normal') }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-divider></v-divider>
      <Pagination
          :page-data='options.page'
          :page-count='totalPage'
          :segment-id='+segmentId'
          :count-list='userListTable.length'
          :total='+totalUserListTable'
          @pageChanged='
          page => {
            options.page = page
            fetchUsers()
          }
        '
          @itemChanged='
          items => {
            options.itemsPerPage = items
            options.page = 1
            fetchUsers()
          }
        '
      />
    </v-card>

    <v-dialog v-model='isUpdateStatusCustomer' max-width='310' persistent>
      <v-card>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-icon color='warning' class='me-1'> {{ icons.mdiAlertOutline }}
          </v-icon
          >
          {{
            update_customer_status_id == 2
                ? $t('confirm_suspension_customer')
                : $t('confirm_cancel_suspension_customer')
          }}
          !
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color='error'
              :loading='updateStatusLoading'
              :disabled='updateStatusLoading'
              @click='updateStatusCustomer'
          >
            {{ $t('confirm') }}
          </v-btn>
          <v-btn color='secondary' outlined @click='isUpdateStatusCustomer = false'>
            {{ $t('cancel') }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model='isImportFile' max-width='500' persistent>
      <v-card>
        <v-card-title>
          {{ $t('import_files_csv') }}
          <v-spacer></v-spacer>
          <v-tooltip color='#212121' top>
            <template v-slot:activator='{ on, attrs }'>
              <a :href='customer_from'>
                <v-btn color='primary' class='ma-2 white--text' v-bind='attrs' x-small fab
                       v-on='on'>
                  <v-icon>{{ icons.mdiCloudDownloadOutline }}</v-icon>
                </v-btn>
              </a
              >
            </template>
            <span>{{ $t('download_form') }}</span>
          </v-tooltip>
        </v-card-title>
        <v-form ref='formImportFile' @submit.prevent='sendFile'>
          <v-card-text>
            <v-autocomplete
                v-model='customer_group_id_for_import'
                :items='customerGroupForImport'
                :rules='[required]'
                :label="$t('select_customer_group')"
                item-text='customer_group_name'
                :no-data-text="$t('no_information')"
                item-value='customer_group_id'
                dense
                outlined
            ></v-autocomplete>
            <v-file-input
                v-model='csvFile'
                class='mt-2'
                :label="$t('choose_a_file')"
                accept='.csv'
                dense
                outlined
                show-size
                @change='checkFile'
            ></v-file-input>
            <span class='ml-3'>Log</span>
            <span v-if='checkLoading' class='primary--text'>{{ $t('checkDataLoading') }}</span>
            <v-sheet outlined rounded='lg' height='100'>
              <v-virtual-scroll :items='dataCheckFile.data' :item-height='20' height='100'>
                <template v-slot:default='{ item, index }'>
                  <span class='text-sm ml-2'
                        :class="item.status_id == 0 ? 'error--text' : 'success--text'">
                    {{ index + 1 }})
                    {{
                      item.status_id == 0
                          ? item.status_message
                          : `ข้อมูลถูกต้อง (${item.customer_prefix} ${item.customer_fname} ${item.customer_lname})`
                    }}
                    <v-icon small :color="item.status_id == 0 ? 'error' : 'success'">{{
                        item.status_id == 0 ? icons.mdiClose : icons.mdiCheck
                      }}</v-icon>
                  </span>
                </template>
              </v-virtual-scroll>
            </v-sheet>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                :disabled='!csvFile || importFileLoading || dataCheckFile.checkfalse >= 1 || checkLoading'
                :loading='importFileLoading'
                type='submit'
                color='primary'
            >
              {{ $t('confirm') }}
            </v-btn>
            <v-btn color='secondary' outlined @click='isImportFile = false'>
              {{ $t('cancel') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiFileImportOutline,
  mdiPlus,
  mdiHumanMale,
  mdiHumanFemale,
  mdiCheck,
  mdiClose,
  mdiAlertOutline,
  mdiInformationOutline,
  mdiPencilOutline,
  mdiDeleteOutline,
  mdiCloudDownloadOutline,
  mdiMagnify
} from '@mdi/js'
import {ref, watch} from '@vue/composition-api'
import customerGroup from '@/api/customer/customerGroup'
import {i18n} from '@/plugins/i18n'
import useCustomerList from './useCustomerList'
import basicStatus from '@/@fake-db/data/basicStatus.json'
import ConverseDate from '@/components/basicComponents/ConverseDate.vue'
import StatusBlock from '@/components/basicComponents/StatusBlock.vue'
import {formatPrice} from '@/plugins/filters'
import AddNewCustomer from './AddNewCustomer.vue'
import EditCustomer from './EditCustomer.vue'
import customer_from from '@/assets/download_files/customer_form.csv'
import {socket} from '@/plugins/socket'
import Pagination from '@/components/basicComponents/Pagination.vue'
import store from '@/store'

export default {
  filters: {
    formatPrice,
  },
  components: {
    ConverseDate,
    Pagination,
    StatusBlock,
    AddNewCustomer,
    EditCustomer,
  },

  setup() {
    const isAddNewCustomer = ref(false)
    const isEditCustomer = ref(false)
    const dataEditCustomer = ref({})
    const customerGroupForImport = ref([])
    const modal = ref(false)
    const modal2 = ref(false)
    const statusCheck = ref('add')
    const {
      totalPage,
      formImportFile,
      customer_group_id_for_import,
      importFileLoading,
      customerForm,
      isImportFile,
      csvFile,
      customer_id_pri,
      updateStatusLoading,
      isUpdateStatusCustomer,
      colorStatus,
      statusShowAlert,
      segmentId,
      dataCheckFile,
      customer_group_id,
      customer_status_id,
      update_customer_status_id,
      date_start,
      date_end,
      userListTable,
      totalUserListTable,
      tableColumns,
      searchQuery,
      checkLoading,
      loading,
      message,
      alertMessage,
      options,
      footer,
      checkFile,
      updateStatusCustomer,
      fetchUsers,
      required,
      sendFile,
      customerAddStatus,
      middleWhereFetchUsers
    } = useCustomerList()
    const customerGroupListData = ref([])
    const customerStatusListData = ref(basicStatus.data)
    const {customerGroupList} = customerGroup
    const body = {
      searchtext: '',
      lang: i18n.locale,
      active_page: 1,
      per_page: '0',
    }
    tableColumns.map(header => ({...header, fixed: false}))

    customerGroupList(body).then(res => {
      const {data} = res
      customerGroupForImport.value = JSON.parse(JSON.stringify(data))
      customerGroupListData.value = data
      customer_group_id_for_import.value = data[0].customer_group_id
      customerGroupListData.value.unshift({
        customer_group_id: '',
        customer_group_name: i18n.t('all'),
      })
    })

    const editCustomer = customer => {
      statusCheck.value = 'edit'
      store.commit('app/statusInputCard', {
        status: 'edit',
      })
      dataEditCustomer.value = customer
      isEditCustomer.value = true
      isAddNewCustomer.value = false
    }

    const addCustomer = () => {
      store.commit('app/statusInputCard', {
        status: 'add',
      })
      statusCheck.value = 'add'
      isAddNewCustomer.value = true
      isEditCustomer.value = false
    }

    watch(isEditCustomer, value => {
      if (value) {
        statusCheck.value = 'edit'
      } else {
        statusCheck.value = 'add'
      }
    })

    return {
      editCustomer,
      addCustomer,
      required,
      formImportFile,
      customer_group_id_for_import,
      customerGroupForImport,
      importFileLoading,
      customer_from,
      alertMessage,
      customerForm,
      isImportFile,
      csvFile,
      dataEditCustomer,
      checkLoading,
      checkFile,
      isEditCustomer,
      customer_id_pri,
      dataCheckFile,
      updateStatusLoading,
      isUpdateStatusCustomer,
      message,
      colorStatus,
      statusShowAlert,
      modal2,
      modal,
      date_start,
      date_end,
      customerStatusListData,
      customer_status_id,
      update_customer_status_id,
      customer_group_id,
      customerGroupListData,
      segmentId,
      userListTable,
      tableColumns,
      searchQuery,
      isAddNewCustomer,
      totalUserListTable,
      loading,
      options,
      footer,
      fetchUsers,
      updateStatusCustomer,
      sendFile,
      statusCheck,
      totalPage,
      customerAddStatus,
      middleWhereFetchUsers,
      // icons
      icons: {
        mdiPlus,
        mdiMagnify,
        mdiFileImportOutline,
        mdiHumanMale,
        mdiHumanFemale,
        mdiCheck,
        mdiClose,
        mdiAlertOutline,
        mdiInformationOutline,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiCloudDownloadOutline,
      },
    }
  },
}
</script>


<style scoped>
.v-input__append-inner-customer-search {
  margin-top: -7px;
  position: absolute;
  right: 0;
}


</style>
