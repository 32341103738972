<template>
  <div>
    <v-dialog v-model='isEditCustomer' persistent max-width='1000'>
      <v-card>
        <v-card-title>
          {{ $t('edit_customer') }}
          <v-spacer></v-spacer>
          <v-icon color='error' @click="$emit('update:is-edit-customer', false)">
            {{ icons.mdiClose }}
          </v-icon>
        </v-card-title>
        <v-form ref='formEditCustomer' @submit.prevent='updateCustomer'>
          <v-card-text>
            <v-row>
              <v-col cols='12' lg='3'>
                <div align='center'>
                  <v-avatar size='110'>
                    <v-img v-if='image == 2' :src="'data:image/png;base64,' + blob_image"></v-img>
                    <v-img v-else :src='blob_image'></v-img>
                  </v-avatar>
                  <v-btn color='primary' class='mt-1 ma-1' small
                         @click='$refs.uploadEditProfile.click()'>
                    <v-icon class='d-sm-none'>
                      {{ icons.mdiCloudUploadOutline }}
                    </v-icon>
                    <span class='d-none d-sm-block'>{{ $t('upload_pictures') }}</span>
                  </v-btn>
                  <input
                    ref='uploadEditProfile'
                    type='file'
                    accept='.jpeg,.png,.jpg,GIF'
                    :hidden='true'
                    @change='previewFiles'
                  />
                </div>
              </v-col>
              <v-col cols='12' lg='9'>
                <v-row>
                  <v-col cols='12' md='6' lg='4'>
                    <v-text-field
                      v-model='dataEditLocal.customer_idcard'
                      :rules='[required]'
                      outlined
                      dense
                      :label="$t('id_card')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols='12' md='6' lg='4'>
                    <v-select
                      v-model='dataEditLocal.customer_prefix'
                      outlined
                      dense
                      :items='prefixList'
                      item-text='name'
                      :rules='[required]'
                      item-value='name'
                      :label="$t('prefix')"
                    >
                    </v-select>
                  </v-col>
                  <v-col cols='12' md='6' lg='4'>
                    <v-text-field v-model='dataEditLocal.customer_gender' outlined dense
                                  :label="$t('gender')" readonly>
                    </v-text-field>
                  </v-col>
                  <v-col cols='12' md='6' lg='4'>
                    <v-text-field
                      v-model='dataEditLocal.customer_fname'
                      outlined
                      dense
                      :rules='[required]'
                      :label="$t('name')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols='12' md='6' lg='4'>
                    <v-text-field
                      v-model='dataEditLocal.customer_lname'
                      outlined
                      :rules='[required]'
                      dense
                      :label="$t('lastname')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols='12' md='6' lg='4'>
                    <v-text-field
                      v-model='dataEditLocal.customer_nname'
                      outlined
                      dense
                      :label="$t('nickname')"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols='12'>
                <v-row>
                  <v-col cols='12' md='6' lg='3'>
                    <v-text-field
                      v-model='dataEditLocal.customer_birthdate'
                      outlined
                      dense
                      :label="$t('birthday')"
                      @click='selectDate = true'
                    ></v-text-field>
                  </v-col>
                  <v-col cols='12' md='6' lg='3'>
                    <v-text-field v-model='customer_age' outlined dense readonly
                                  :label="$t('age')"></v-text-field>
                  </v-col>
                  <v-col cols='12' md='6' lg='3'>
                    <v-select
                      v-model='dataEditLocal.customer_blood'
                      outlined
                      :items='bloodTypeList'
                      dense
                      item-value='name'
                      item-text='name'
                      :label="$t('blood_type')"
                    ></v-select>
                  </v-col>
                  <v-col cols='12' md='6' lg='3'>
                    <v-text-field
                      v-model='dataEditLocal.customer_email'
                      outlined
                      dense
                      :rules='[emailValidator]'
                      :label="$t('email')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols='12' md='6' lg='3'>
                    <v-text-field
                      v-model='dataEditLocal.customer_tel'
                      outlined
                      :rules='[required]'
                      dense
                      :label="$t('tel')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols='12' md='6' lg='3'>
                    <v-text-field
                      v-model='dataEditLocal.customer_address'
                      outlined
                      dense
                      :label="$t('address')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols='12' md='6' lg='3'>
                    <v-autocomplete
                      v-model='address'
                      :items='locationList'
                      item-text='SEARCH'
                      hide-no-data
                      :label="$t('sub_district')"
                      return-object
                      :no-data-text="$t('no_data')"
                      dense
                      outlined
                    >
                      <template v-slot:item='{ item }'>
                        {{ item.SEARCH }}
                      </template>
                      <template v-slot:selection='{ item }'>
                        {{ item.SUB_DISTRICT_NAME }}
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols='12' md='6' lg='3'>
                    <v-autocomplete
                      v-model='address'
                      :items='locationList'
                      item-text='SEARCH'
                      :label="$t('district')"
                      hide-no-data
                      :no-data-text="$t('no_data')"
                      return-object
                      dense
                      outlined
                    >
                      <template v-slot:item='{ item }'>
                        {{ item.SEARCH }}
                      </template>
                      <template v-slot:selection='{ item }'>
                        {{ item.DISTRICT_NAME }}
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols='12' md='6' lg='3'>
                    <v-autocomplete
                      v-model='address'
                      :items='locationList'
                      hide-no-data
                      item-text='SEARCH'
                      :label="$t('province')"
                      :no-data-text="$t('no_data')"
                      return-object
                      dense
                      outlined
                    >
                      <template v-slot:item='{ item }'>
                        {{ item.SEARCH }}
                      </template>
                      <template v-slot:selection='{ item }'>
                        {{ item.PROVINCE_NAME }}
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols='12' md='6' lg='3'>
                    <v-autocomplete
                      v-model='address'
                      :items='locationList'
                      :no-data-text="$t('no_data')"
                      :label="$t('zip_code')"
                      item-text='SEARCH'
                      hide-no-data
                      return-object
                      dense
                      outlined
                    >
                      <template v-slot:item='{ item }'>
                        {{ item.SEARCH }}
                      </template>
                      <template v-slot:selection='{ item }'>
                        {{ item.ZIPCODE }}
                      </template>
                    </v-autocomplete>
                  </v-col>

                  <v-col cols='12' md='2'>
                    <v-btn color='info' outlined @click="()=>{
                             typeAddNewAddress = 'customer'
                             isAddNewLocation = true
                           }">
                      {{ $t('add_new_address') }}
                    </v-btn>
                  </v-col>
                  <v-col cols='12' md='6'>
                    <v-autocomplete
                      v-model='customer_allergic'
                      chips
                      small-chips
                      multiple
                      :items='customerDrugList'
                      :no-data-text="$t('no_data')"
                      item-value='drug_id_pri'
                      item-text='drug_name'
                      :label="$t('be_allergic')"
                      outlined
                      dense
                    ></v-autocomplete>
                    <!-- <v-textarea
                      v-model="dataEditLocal.customer_allergic"
                      name="input-7-4"
                      outlined
                      dense
                      :label="$t('be_allergic')"
                      rows="2"
                    ></v-textarea> -->
                  </v-col>
                  <v-col cols='12' md='6'>
                    <v-textarea
                      v-model='dataEditLocal.customer_allergic'
                      name='input-7-4'
                      outlined
                      dense
                      :label="`${$t('be_allergic')} ${$t('other')}`"
                      rows='2'
                    ></v-textarea>
                  </v-col>
                  <v-col cols='12' md='4'>
                    <v-textarea
                      v-model='dataEditLocal.customer_disease'
                      name='input-7-4'
                      outlined
                      dense
                      :label="$t('congenital_disease')"
                      rows='2'
                    ></v-textarea>
                  </v-col>
                  <v-col cols='12' md='6'>
                    <v-textarea
                      v-model='dataEditLocal.customer_comment'
                      name='input-7-4'
                      outlined
                      dense
                      :label="$t('note')"
                      rows='2'
                    ></v-textarea>
                  </v-col>
                  <v-col cols='12' md='6'>
                    <v-autocomplete
                      v-model='customer_tag_id'
                      chips
                      small-chips
                      multiple
                      :items='dataCustomerTagList'
                      item-value='customer_tag_id'
                      item-text='customer_tag_name'
                      :label="$t('customer_tag')"
                      outlined
                      dense
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols='12' md='6'>
                    <v-autocomplete
                      v-model='dataEditLocal.customer_group_id'
                      :items='dataCustomerGroupList'
                      item-value='customer_group_id'
                      item-text='customer_group_name'
                      :no-data-text="$t('no_information')"
                      :label="$t('group_customers')"
                      outlined
                      dense
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols='12' md='6'>
                    <v-text-field
                      v-model='dataEditLocal.customer_point'
                      outlined
                      :label="$t('current_points')"
                      dense
                      type='number'
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols='12' md='6'>
                    <v-text-field
                      v-model='dataEditLocal.customer_point_change'
                      outlined
                      :label="$t('change_points')"
                      :placeholder="$t('change_points_by')"
                      type='number'
                      dense
                      reverse
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
                <h4 class='mt-3'>
                  <v-icon>{{ icons.mdiDomain }}</v-icon>
                  {{ $t('datacompany') }}
                </h4>
                <v-row>
                  <v-col cols='12' md='6' lg='3'>
                    <v-text-field
                      v-model='dataEditLocal.company_name'
                      outlined
                      dense
                      :label="$t('company_name')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols='12' md='6' lg='3'>
                    <v-text-field
                      v-model='dataEditLocal.company_tax'
                      outlined
                      dense
                      :label="$t('tax_number')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols='12' md='6' lg='3'>
                    <v-text-field
                      v-model='dataEditLocal.company_email'
                      outlined
                      dense
                      :label="$t('email')"
                      :rules='[emailValidator]'
                    ></v-text-field>
                  </v-col>
                  <v-col cols='12' md='6' lg='3'>
                    <v-text-field v-model='dataEditLocal.company_tel' outlined dense
                                  :label="$t('tel')"></v-text-field>
                  </v-col>
                  <v-col cols='12' md='6' lg='3'>
                    <v-text-field
                      v-model='dataEditLocal.company_address'
                      outlined
                      dense
                      :label="$t('address')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols='12' md='6' lg='3'>
                    <v-autocomplete
                      v-model='localtionCompay'
                      :items='locationList'
                      item-text='SEARCH'
                      hide-no-data
                      :label="$t('sub_district')"
                      return-object
                      :no-data-text="$t('no_data')"
                      dense
                      outlined
                    >
                      <template v-slot:item='{ item }'>
                        {{ item.SEARCH }}
                      </template>
                      <template v-slot:selection='{ item }'>
                        {{ item.SUB_DISTRICT_NAME }}
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols='12' md='6' lg='3'>
                    <v-autocomplete
                      v-model='localtionCompay'
                      :items='locationList'
                      item-text='SEARCH'
                      :label="$t('district')"
                      hide-no-data
                      :no-data-text="$t('no_data')"
                      return-object
                      dense
                      outlined
                    >
                      <template v-slot:item='{ item }'>
                        {{ item.SEARCH }}
                      </template>
                      <template v-slot:selection='{ item }'>
                        {{ item.DISTRICT_NAME }}
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols='12' md='6' lg='3'>
                    <v-autocomplete
                      v-model='localtionCompay'
                      :items='locationList'
                      hide-no-data
                      item-text='SEARCH'
                      :label="$t('province')"
                      :no-data-text="$t('no_data')"
                      return-object
                      dense
                      outlined
                    >
                      <template v-slot:item='{ item }'>
                        {{ item.SEARCH }}
                      </template>
                      <template v-slot:selection='{ item }'>
                        {{ item.PROVINCE_NAME }}
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols='12' md='6' lg='3'>
                    <v-autocomplete
                      v-model='localtionCompay'
                      :items='locationList'
                      :no-data-text="$t('no_data')"
                      :label="$t('zip_code')"
                      item-text='SEARCH'
                      hide-no-data
                      return-object
                      dense
                      outlined
                    >
                      <template v-slot:item='{ item }'>
                        {{ item.SEARCH }}
                      </template>
                      <template v-slot:selection='{ item }'>
                        {{ item.ZIPCODE }}
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols='12' md='3'>
                    <v-btn color='info' outlined @click="()=>{
                             typeAddNewAddress = 'company'
                             isAddNewLocation = true
                           }">
                      {{ $t('add_new_address') }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color='primary' type='submit' :loading='loading' :disabled='loading'>
              {{ $t('save') }}
            </v-btn>
            <v-btn color='secondary' outlined @click="$emit('update:is-edit-customer', false)">
              {{ $t('cancel') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog
      ref='selectDate2'
      v-model='selectDate'
      :return-value.sync='dataEditLocal.customer_birthdate'
      width='290px'
    >
      <v-date-picker v-model='dataEditLocal.customer_birthdate' :locale='$i18n.locale' scrollable>
        <v-spacer></v-spacer>
        <v-btn text color='primary'
               @click='$refs.selectDate2.save(dataEditLocal.customer_birthdate)'>
          {{ $t('confirm') }}
        </v-btn>
        <v-btn text color='secondary' @click='selectDate = false'>
          {{ $t('cancel') }}
        </v-btn>
        <v-spacer></v-spacer>
      </v-date-picker>
    </v-dialog>
    <v-dialog v-model='isAddNewLocation' max-width='400' persistent>
      <v-card>
        <v-card-title> {{ $t('add_new_address') }}
                       ({{ typeAddNewAddress === 'company' ? $t('company') : $t('personal') }})
        </v-card-title>
        <v-form ref='formAddNewLocation' class='px-5' @submit.prevent='mapAddress'>
          <v-text-field
            v-model='customSubDistrict'
            :label="$t('sub_district')"
            :rules='[required]'
            outlined
            dense
          ></v-text-field>
          <v-text-field
            v-model='customDistrict'
            :label="$t('district')"
            :rules='[required]'
            outlined
            dense
          ></v-text-field>
          <v-text-field
            v-model='customProvince'
            dense
            :label="$t('province')"
            :rules='[required]'
            outlined
          ></v-text-field>
          <v-text-field
            v-model='customZipcode'
            :label="$t('zip_code')"
            :rules='[required]'
            outlined
            dense
          ></v-text-field>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn type='submit' color='primary'>
              {{ $t('save') }}
            </v-btn>
            <v-btn color='secodary' outlined @click='isAddNewLocation = false'>
              {{ $t('cancel') }}
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mdiCloudUploadOutline, mdiDomain, mdiClose } from '@mdi/js'
import { ref, watch } from '@vue/composition-api'
import { required, emailValidator } from '@core/utils/validation'
import genders from '@/@fake-db/data/genders.json'
import prefix from '@/@fake-db/data/prefix.json'
import location_new from '@/@fake-db/data/location_new.json'
import bloodGroup from '@/@fake-db/data/bloodGroup.json'
import { sum_birthdate } from '@/plugins/filters'
import customerGroup from '@/api/customer/customerGroup'
import customerTag from '@/api/customer/customerTag'
import customer from '@/api/customer/customer'
import { i18n } from '@/plugins/i18n'
import store from '@/store'
import { socket } from '@/plugins/socket'

export default {
  model: {
    prop: 'isEditCustomer',
    event: 'update:is-edit-customer',
  },
  props: {
    isEditCustomer: {
      type: Boolean,
      default: false,
    },
    dataEdit: {
      type: Object,
      default: null,
    },
    statusCheck: {
      type: String,
      default: 'edit',
    },
  },
  setup(props, { emit }) {
    socket.on('inserted', async data => {
      if (store.state.app.status == 'edit') {
        store.commit('app/ALERT', {
          message: 'card_detect',
          color: 'success',
        })
        mapDataCardReader(data)
        image.value = 2
      }
    })
    socket.on('removed', () => {
      if (store.state.app.status == 'edit') {
        store.commit('app/ALERT', {
          message: 'card_not_detect',
          color: 'warning',
        })
        image.value = 1
      }
    })
    const formEditCustomer = ref(null)
    const formAddNewLocation = ref(null)
    const dataEditLocal = ref({})
    const address = ref({
      SUB_DISTRICT_NAME: '',
      DISTRICT_NAME: '',
      PROVINCE_NAME: '',
      ZIPCODE: '',
    })
    const isAddNewLocation = ref(false)
    const customer_tag_id = ref([])
    const customer_age = ref('')
    const customer_image = ref(null)
    const image = ref(1)
    const loading = ref(false)

    // block compay
    const localtionCompay = ref({
      SUB_DISTRICT_NAME: '',
      DISTRICT_NAME: '',
      PROVINCE_NAME: '',
      ZIPCODE: '',
    })

    // add new address
    const customSubDistrict = ref('')
    const customDistrict = ref('')
    const customProvince = ref('')
    const customZipcode = ref('')
    const typeAddNewAddress = ref('customer')
    const selectDate = ref(false)
    const genderList = ref(genders.data)
    const prefixList = ref(prefix.data)
    const bloodTypeList = ref(bloodGroup.data)
    const locationList = ref(location_new)
    const blob_image = ref(null)
    const dataCustomerGroupList = ref([])
    const dataCustomerTagList = ref([])
    const customer_allergic = ref([])
    const customerDrugList = ref([])

    // alert valiable
    const { customerGroupList } = customerGroup
    const { customerTagList } = customerTag
    const { customerUpdate } = customer
    const bodyCustomerGroup = {
      searchtext: '',
      lang: i18n.locale,
      active_page: 1,
      per_page: '0',
    }

    const mapDataCardReader = data => {
      dataEditLocal.value.customer_prefix = data.th_name.prefix == 'น.ส.' ? 'นางสาว' : data.th_name.prefix
      dataEditLocal.value.customer_fname = data.th_name.firstname
      dataEditLocal.value.customer_lname = data.th_name.lastname
      dataEditLocal.value.customer_idcard = data.citizen_code
      dataEditLocal.value.customer_image_card = data.photo
      blob_image.value = data.photo
      dataEditLocal.value.customer_birthdate = `${parseInt(data.dob.year) - 543}-${data.dob.month}-${data.dob.day}`
      replaceAddress(data.address)
    }

    const replaceAddress = dataAddress => {
      const address_2 = dataAddress.includes('ตำบล') ? dataAddress.split('ตำบล') : dataAddress.split('แขวง')
      const address_3 = address_2[1].split(' ')
      const tumbon = address_3[0]
      const distritc = address_3[1].replace('อำเภอ', '').replace('เขต', '')
      const province = address_3[2].replace('จังหวัด', '')
      const ZipCode = locationList.value.find(item => item.DISTRICT_NAME == distritc)
      dataEditLocal.value.customer_address = address_2[0].trim()
      address.value = {
        SUB_DISTRICT_NAME: tumbon,
        DISTRICT_NAME: distritc,
        PROVINCE_NAME: province,
        ZIPCODE: ZipCode.ZIPCODE,
        SEARCH: `${tumbon} > ${distritc} > ${province} > ${ZipCode.ZIPCODE}`,
      }
    }

    const getCustomerGroup = () => {
      customerGroupList(bodyCustomerGroup).then(res => {
        const { data } = res
        dataCustomerGroupList.value = data
      })
    }

    const getCustomerDrugList = () => {
      customer.customerDrugList({
        searchtext: '',
        active_page: '1',
        per_page: '0',
      }).then(res => (customerDrugList.value = res.data))
    }

    const bodyCustomerTag = {
      searchtext: '',
      active_page: 1,
      per_page: '0',
    }

    const getCustomerTagList = () => {
      customerTagList(bodyCustomerTag).then(res => {
        const { data } = res
        dataCustomerTagList.value = data
      })
    }

    const previewFiles = event => {
      image.value = 1
      customer_image.value = null
      blob_image.value = null
      customer_image.value = event.target.files[event.target.files.length - 1]
      blob_image.value = URL.createObjectURL(event.target.files[event.target.files.length - 1])
    }

    const mapGender = prefix => {
      if (prefix == 'นาย' || prefix == 'ด.ช.') {
        dataEditLocal.value.customer_gender = genderList.value[0].name
      } else if (prefix == 'นาง' || prefix == 'นางสาว' || prefix == 'ด.ญ.' || prefix == 'น.ส.') {
        dataEditLocal.value.customer_gender = genderList.value[1].name
      } else if (prefix == 'Mr.' || prefix == 'Master') {
        dataEditLocal.value.customer_gender = genderList.value[2].name
      } else if (prefix == 'Mrs.' || prefix == 'Miss') {
        dataEditLocal.value.customer_gender = genderList.value[3].name
      } else {
        dataEditLocal.value.customer_gender = genderList.value[4].name
      }
    }

    const ageCalculate = birthday => {
      customer_age.value = sum_birthdate(birthday)
    }

    const mapAddress = () => {
      const isInvalidForm = formAddNewLocation.value.validate()
      if (!isInvalidForm) return
      locationList.value.push({
        SEARCH: `${customSubDistrict.value} > ${customDistrict.value} > ${customProvince.value} > ${customZipcode.value}`,
        PROVINCE_NAME: customProvince.value,
        DISTRICT_NAME: customDistrict.value,
        SUB_DISTRICT_NAME: customSubDistrict.value,
        ZIPCODE: customZipcode.value,
      })
      if (typeAddNewAddress.value === 'company') {
        localtionCompay.value = {
          SEARCH: `${customSubDistrict.value} > ${customDistrict.value} > ${customProvince.value} > ${customZipcode.value}`,
          PROVINCE_NAME: customProvince.value,
          DISTRICT_NAME: customDistrict.value,
          SUB_DISTRICT_NAME: customSubDistrict.value,
          ZIPCODE: customZipcode.value,
        }
      } else {
        address.value = {
          SEARCH: `${customSubDistrict.value} > ${customDistrict.value} > ${customProvince.value} > ${customZipcode.value}`,
          PROVINCE_NAME: customProvince.value,
          DISTRICT_NAME: customDistrict.value,
          SUB_DISTRICT_NAME: customSubDistrict.value,
          ZIPCODE: customZipcode.value,
        }
      }
      isAddNewLocation.value = false
      customSubDistrict.value = ''
      customDistrict.value = ''
      customProvince.value = ''
      customZipcode.value = ''
    }

    const updateCustomer = () => {
      const isInvalidForm = formEditCustomer.value.validate()
      if (!isInvalidForm) return
      loading.value = true
      const formData = new FormData()
      formData.append('customer_idcard', dataEditLocal.value.customer_idcard)
      formData.append('customer_id_pri', dataEditLocal.value.customer_id_pri)
      formData.append('customer_group_id', dataEditLocal.value.customer_group_id)
      formData.append(
        'customer_prefix',
        dataEditLocal.value.customer_prefix == 'ไม่ระบุ' ? '' : dataEditLocal.value.customer_prefix,
      )
      formData.append('customer_fname', dataEditLocal.value.customer_fname)
      formData.append('customer_lname', dataEditLocal.value.customer_lname)
      formData.append('customer_nname', dataEditLocal.value.customer_nname)
      formData.append(
        'customer_gender',
        dataEditLocal.value.customer_gender == 'ไม่ระบุ' ? '' : dataEditLocal.value.customer_gender,
      )
      formData.append('customer_blood', dataEditLocal.value.customer_blood)
      formData.append('customer_email', dataEditLocal.value.customer_email)
      formData.append('customer_tel', dataEditLocal.value.customer_tel)
      formData.append('customer_birthdate', dataEditLocal.value.customer_birthdate)
      formData.append('customer_address', dataEditLocal.value.customer_address)
      formData.append('customer_district', address.value ? address.value.SUB_DISTRICT_NAME : '')
      formData.append('customer_amphoe', address.value ? address.value.DISTRICT_NAME : '')
      formData.append('customer_province', address.value ? address.value.PROVINCE_NAME : '')
      formData.append('customer_zipcode', address.value ? address.value.ZIPCODE : '')
      formData.append('customer_allergic', dataEditLocal.value.customer_allergic)
      formData.append('customer_disease', dataEditLocal.value.customer_disease)
      formData.append('customer_comment', dataEditLocal.value.customer_comment)
      formData.append('customer_point', dataEditLocal.value.customer_point)
      formData.append('customer_point_change', dataEditLocal.value.customer_point_change)
      formData.append('company_name', dataEditLocal.value.company_name)
      formData.append('company_tax', dataEditLocal.value.company_tax)
      formData.append('company_tel', dataEditLocal.value.company_tel)
      formData.append('company_email', dataEditLocal.value.company_email)
      formData.append('company_address', dataEditLocal.value.company_address)
      formData.append('company_district', localtionCompay.value ? localtionCompay.value.SUB_DISTRICT_NAME : '')
      formData.append('company_amphoe', localtionCompay.value ? localtionCompay.value.DISTRICT_NAME : '')
      formData.append('company_province', localtionCompay.value ? localtionCompay.value.PROVINCE_NAME : '')
      formData.append('company_zipcode', localtionCompay.value ? localtionCompay.value.ZIPCODE : '')

      if (customer_allergic.value.length > 0) {
        customer_allergic.value.forEach((item, index) => {
          formData.append(`drug_id_pri[${index}]`, item)
        })
      }

      customer_tag_id.value.forEach((item, index) => {
        formData.append(`customer_tag_id[${index}]`, item)
      })
      formData.append('image', image.value)
      formData.append('customer_image', customer_image.value)
      formData.append('customer_image_card', dataEditLocal.value.customer_image_card)
      customerUpdate(formData).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        emit('update:is-edit-customer', false)
        loading.value = false
        emit('onUpdate', res.response)
      })
    }

    watch(
      () => dataEditLocal.value.customer_prefix,
      newvalue => {
        mapGender(newvalue)
      },
    )

    watch(
      () => dataEditLocal.value.customer_birthdate,
      newvalue => {
        ageCalculate(newvalue)
      },
    )

    watch(
      () => props.isEditCustomer,
      value => {
        if (value) {
          getCustomerGroup()
          getCustomerDrugList()
          getCustomerTagList()
          dataEditLocal.value = JSON.parse(JSON.stringify(props.dataEdit))
          customer_tag_id.value = []
          customer_allergic.value = []
          blob_image.value = dataEditLocal.value.customer_image
          image.value = 1
          dataEditLocal.value.customer_gender =
            dataEditLocal.value.customer_prefix == '-' ||
            dataEditLocal.value.customer_prefix == null ||
            dataEditLocal.value.customer_prefix == 'null' ||
            dataEditLocal.value.customer_prefix == ''
              ? 'ไม่ระบุ'
              : dataEditLocal.value.customer_prefix
          if (dataEditLocal.value.tag) {
            dataEditLocal.value.tag.forEach(element => {
              customer_tag_id.value.push(element.customer_tag_id)
            })
          }
          if (dataEditLocal.value.drug) {
            if (dataEditLocal.value.drug.length) {
              dataEditLocal.value.drug.forEach(element => {
                customer_allergic.value.push(element.drug_id_pri)
              })
            }
          }
          locationList.value.push({
            SUB_DISTRICT_NAME: dataEditLocal.value.customer_district,
            DISTRICT_NAME: dataEditLocal.value.customer_amphoe,
            PROVINCE_NAME: dataEditLocal.value.customer_province,
            ZIPCODE: dataEditLocal.value.customer_zipcode,
            SEARCH: `${dataEditLocal.value.customer_district} > ${dataEditLocal.value.customer_amphoe} > ${dataEditLocal.value.customer_province} > ${dataEditLocal.value.customer_zipcode}`,
          })
          address.value = {
            SUB_DISTRICT_NAME: dataEditLocal.value.customer_district,
            DISTRICT_NAME: dataEditLocal.value.customer_amphoe,
            PROVINCE_NAME: dataEditLocal.value.customer_province,
            ZIPCODE: dataEditLocal.value.customer_zipcode,
            SEARCH: `${dataEditLocal.value.customer_district} > ${dataEditLocal.value.customer_amphoe} > ${dataEditLocal.value.customer_province} > ${dataEditLocal.value.customer_zipcode}`,
          }
          localtionCompay.value = {
            SUB_DISTRICT_NAME: dataEditLocal.value.company_district,
            DISTRICT_NAME: dataEditLocal.value.company_amphoe,
            PROVINCE_NAME: dataEditLocal.value.company_province,
            ZIPCODE: dataEditLocal.value.company_zipcode,
            SEARCH: `${dataEditLocal.value.company_district} > ${dataEditLocal.value.company_amphoe} > ${dataEditLocal.value.company_province} > ${dataEditLocal.value.company_zipcode}`,
          }
          mapGender(dataEditLocal.value.customer_prefix)
          ageCalculate(dataEditLocal.value.customer_birthdate)
        }
        if (!value) {
          store.commit('app/statusInputCard', {
            status: 'add',
          })
        }
      },
    )

    return {
      loading,
      formEditCustomer,
      customer_tag_id,
      customerDrugList,
      customer_allergic,
      customer_age,
      isAddNewLocation,
      dataEditLocal,
      address,
      localtionCompay,
      bloodTypeList,
      locationList,
      selectDate,
      blob_image,
      previewFiles,
      required,
      emailValidator,
      prefixList,
      updateCustomer,
      dataCustomerGroupList,
      dataCustomerTagList,
      customSubDistrict,
      customDistrict,
      customProvince,
      customZipcode,
      mapAddress,
      image,
      formAddNewLocation,
      typeAddNewAddress,
      icons: {
        mdiCloudUploadOutline,
        mdiDomain,
        mdiClose,
      },
    }
  },
}
</script>
