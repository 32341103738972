import { required } from '@/@core/utils/validation'
import checkImportFile from '@/api/checkImportFile'
import customer from '@/api/customer/customer'
import footerDataTables from '@/components/basicComponents/footerDataTables.json'
import footerDataTablesEN from '@/components/basicComponents/footerDataTablesEN.json'
import { i18n } from '@/plugins/i18n'
import store from '@/store'
import { ref, watch } from '@vue/composition-api'

export default function useCustomerList() {
  const formImportFile = ref(null)
  const isImportFile = ref(false)
  const csvFile = ref(null)
  const importFileLoading = ref(false)

  const userListTable = ref([])

  const tableColumns = [
    {
      text: '#',
      value: 'customer_id_pri',
      width: '50',
    },

    { text: i18n.t('picture'), value: 'customer_image', width: '50' },
    { text: i18n.t('customer_code'), value: 'customer_id', width: '100' },
    { text: i18n.t('firstname_lastname'), value: 'customer_fname', width: '220' },
    { text: i18n.t('id_card'), value: 'customer_idcard', width: '150' },
    {
      text: i18n.t('gender'),
      value: 'customer_gender',
      width: '50',
      align: 'center',
    },
    {
      text: i18n.t('tel'),
      value: 'customer_tel',
      width: '50',
      align: 'center',
    },
    { text: 'Line', value: 'line_status_id', width: '50' },
    { text: i18n.t('group_customers'), value: 'customer_group_name', width: '120' },
    {
      text: i18n.t('reward_points'),
      value: 'customer_point',
      width: '90',
      align: 'end',
    },
    {
      text: i18n.t('add_on'),
      value: 'customer_create',
      width: '120',
      align: 'center',
    },
    {
      text: i18n.t('status'),
      value: 'customer_status_id',
      width: '50',
      align: 'center',
    },
    {
      text: i18n.t('option'),
      value: 'actions',
      align: 'center fixed',
      width: '130',
    },
  ]

  const message = ref('')

  const customer_id_pri = ref('')
  const updateStatusLoading = ref(false)
  const isUpdateStatusCustomer = ref(false)
  const searchQuery = ref('')
  const customer_group_id = ref('')
  const customer_group_id_for_import = ref('')
  const customer_status_id = ref(1)
  const update_customer_status_id = ref(1)
  const date_start = ref('')
  const date_end = ref('')
  const loading = ref(false)
  const options = ref({})
  const totalUserListTable = ref(0)
  const totalPage = ref(0)
  const dataCheckFile = ref({})
  const checkLoading = ref(false)
  const customerAddStatus = ref('')

  const segmentId = ref(0)
  const footer = ref(i18n.locale == 'en' ? footerDataTablesEN : footerDataTables)
  const { customerList, customerStatusUpdate, csvFileImport, customerById2 } = customer
  const alertMessage = (msg, name) => {
    message.value = msg
    searchQuery.value = name
  }

  // fetch data
  const fetchUsers = event => {
    loading.value = true
    const body = {
      searchtext: searchQuery.value,
      customer_group_id: customer_group_id.value,
      date_start: date_start.value,
      date_end: date_end.value,
      customer_status_id: customer_status_id.value,
      active_page: options.value.page,
      per_page: options.value.itemsPerPage == -1 ? '0' : options.value.itemsPerPage,
    }
    customerList(body).then(res => {
      const { count, count_of_page, data, segment, count_of_add } = res
      customerAddStatus.value = count_of_add
      userListTable.value = data
      totalUserListTable.value = count
      segmentId.value = segment
      totalPage.value = count_of_page
      if (event == 'add') {
        options.value.page = count_of_page
      } else if (event == 'delete') {
        if (+options.value.page >= +count_of_page) {
          options.value.page = count_of_page
        }
      }
      loading.value = false
    })
  }

  const updateStatusCustomer = () => {
    updateStatusLoading.value = true
    const body = {
      customer_id_pri: customer_id_pri.value,
      customer_status_id: update_customer_status_id.value,
    }
    customerStatusUpdate(body).then(res => {
      store.commit('app/ALERT', {
        message: res.message,
        color: res.response ? 'success' : 'error',
      })
      updateStatusLoading.value = false
      isUpdateStatusCustomer.value = false
      fetchUsers('delete')
    })
  }

  const sendFile = () => {
    const isFormValid = formImportFile.value.validate()
    if (!isFormValid) return
    importFileLoading.value = true
    const formData = new FormData()
    formData.append('customer_group_id', customer_group_id_for_import.value)
    formData.append('file', csvFile.value)
    csvFileImport(formData).then(res => {
      store.commit('app/ALERT', {
        message: res.message,
        color: res.response ? 'success' : 'error',
      })
      importFileLoading.value = false
      isImportFile.value = false
      csvFile.value = null
      customer_group_id_for_import.value = ''
    })
  }

  const checkFile = () => {
    checkLoading.value = true
    const formData = new FormData()
    formData.append('customer_group_id', customer_group_id_for_import.value)
    formData.append('file', csvFile.value)
    checkImportFile.checkImportFileCustomer(formData).then(res => {
      dataCheckFile.value = res
      checkLoading.value = false
    })
  }

  watch([customer_group_id, customer_status_id, date_start, date_end, options], (newvalue, oldvalue) => {
    loading.value = true
    if (
      newvalue[0] !== oldvalue[0] ||
      newvalue[1] !== oldvalue[1] ||
      newvalue[2] !== oldvalue[2] ||
      newvalue[3] !== oldvalue[3]
    ) {
      options.value.page = 1
    }

    fetchUsers()
  })

  // watch(searchQuery, (newvalue) => {
  //   if (newvalue == '') {
  //     options.value.page = 1
  //     fetchUsers()
  //   } else if (newvalue.length >= 3) {
  //     options.value.page = 1
  //     fetchUsers()
  //   }
  // })

  const middleWhereFetchUsers = () => {
    options.value.page = 1
    fetchUsers()
  }

  watch(isImportFile, newvalue => {
    if (newvalue) {
      csvFile.value = null
    }
  })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    checkFile,
    formImportFile,
    required,
    importFileLoading,
    isImportFile,
    csvFile,
    customer_id_pri,
    updateStatusLoading,
    isUpdateStatusCustomer,
    segmentId,
    customer_group_id,
    customer_group_id_for_import,
    customer_status_id,
    update_customer_status_id,
    date_start,
    date_end,
    userListTable,
    dataCheckFile,
    tableColumns,
    checkLoading,
    searchQuery,
    totalUserListTable,
    loading,
    options,
    footer,
    totalPage,
    updateStatusCustomer,
    fetchUsers,
    sendFile,
    message,
    alertMessage,
    customerAddStatus,
    middleWhereFetchUsers
  }
}
