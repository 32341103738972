<template>
  <div>
    <v-dialog v-model='isAddNewCustomer' persistent max-width='1000'>
      <v-card>
        <v-card-title>
          {{ $t('add_customers') }}
          <v-chip class='v-chip-light-bg primary--text ml-1' label color='primary'>
            {{ newCustomerId }}
          </v-chip>
          <v-spacer></v-spacer>
          <v-icon color='error' @click="$emit('update:is-add-new-customer', false)">
            {{ icons.mdiClose }}
          </v-icon>
        </v-card-title>
        <v-form ref='formAddCustomer' @submit.prevent='newCustomer'>
          <v-card-text>
            <v-row>
              <v-col cols='12' lg='3'>
                <div align='center'>
                  <v-avatar size='110' color='#9E9E9E'>
                    <v-img v-if='image == 2' :src="'data:image/png;base64,' + blob_image"></v-img>
                    <v-img v-else :src='blob_image'></v-img>
                  </v-avatar>
                  <v-btn color='primary' class='mt-1 ma-1' small @click='$refs.refInputEl.click()'>
                    <v-icon class='d-sm-none'>
                      {{ icons.mdiCloudUploadOutline }}
                    </v-icon>
                    <span class='d-none d-sm-block'>{{ $t('upload_pictures') }}</span>
                  </v-btn>
                  <input
                    ref='refInputEl'
                    type='file'
                    accept='.jpeg,.png,.jpg,GIF'
                    :hidden='true'
                    @change='previewFiles'
                  />
                </div>
              </v-col>
              <v-col cols='12' lg='9'>
                <v-row>
                  <v-col cols='12' md='6' lg='4'>
                    <v-text-field
                      v-model='customer_idcard'
                      :rules='[required]'
                      outlined
                      dense
                      :label="$t('id_card')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols='12' md='6' lg='4'>
                    <v-select
                      v-model='customer_prefix'
                      outlined
                      dense
                      :items='prefixList'
                      item-text='name'
                      :rules='[required]'
                      item-value='name'
                      :label="$t('prefix')"
                    >
                    </v-select>
                  </v-col>
                  <v-col cols='12' md='6' lg='4'>
                    <v-text-field v-model='customer_gender' outlined dense :label="$t('gender')"
                                  readonly>
                    </v-text-field>
                  </v-col>
                  <v-col cols='12' md='6' lg='4'>
                    <v-text-field
                      v-model='customer_fname'
                      outlined
                      dense
                      :rules='[required]'
                      :label="$t('name')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols='12' md='6' lg='4'>
                    <v-text-field
                      v-model='customer_lname'
                      outlined
                      :rules='[required]'
                      dense
                      :label="$t('lastname')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols='12' md='6' lg='4'>
                    <v-text-field v-model='customer_nname' outlined dense
                                  :label="$t('nickname')"></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols='12'>
                <v-row>
                  <v-col cols='12' md='6' lg='3'>
                    <v-text-field
                      v-model='customer_birthdate'
                      outlined
                      dense
                      :label="$t('birthday')"
                      @click='selectDate = true'
                    ></v-text-field>
                  </v-col>
                  <v-col cols='12' md='6' lg='3'>
                    <v-text-field v-model='customer_age' outlined dense readonly
                                  :label="$t('age')"></v-text-field>
                  </v-col>
                  <v-col cols='12' md='6' lg='3'>
                    <v-select
                      v-model='customer_blood'
                      outlined
                      :items='bloodTypeList'
                      dense
                      item-value='name'
                      item-text='name'
                      :label="$t('blood_type')"
                    ></v-select>
                  </v-col>
                  <v-col cols='12' md='6' lg='3'>
                    <v-text-field
                      v-model='customer_email'
                      outlined
                      dense
                      :rules='[emailValidator]'
                      :label="$t('email')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols='12' md='6' lg='3'>
                    <v-text-field
                      v-model='customer_tel'
                      outlined
                      :rules='[required, telValidator]'
                      dense
                      :label="$t('tel')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols='12' md='6' lg='3'>
                    <v-text-field v-model='customer_address' outlined dense
                                  :label="$t('address')"></v-text-field>
                  </v-col>
                  <v-col cols='12' md='6' lg='3'>
                    <v-autocomplete
                      v-model='address'
                      :items='locationList'
                      item-text='SEARCH'
                      :label="$t('sub_district')"
                      return-object
                      :no-data-text="$t('no_data')"
                      dense
                      outlined
                    >
                      <template v-slot:item='{ item }'>
                        {{ item.SEARCH }}
                      </template>
                      <template v-slot:selection='{ item }'>
                        {{ item.SUB_DISTRICT_NAME }}
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols='12' md='6' lg='3'>
                    <v-autocomplete
                      v-model='address'
                      :items='locationList'
                      item-text='SEARCH'
                      :label="$t('district')"
                      :no-data-text="$t('no_data')"
                      return-object
                      dense
                      outlined
                    >
                      <template v-slot:item='{ item }'>
                        {{ item.SEARCH }}
                      </template>
                      <template v-slot:selection='{ item }'>
                        {{ item.DISTRICT_NAME }}
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols='12' md='6' lg='3'>
                    <v-autocomplete
                      v-model='address'
                      :items='locationList'
                      item-text='SEARCH'
                      :label="$t('province')"
                      :no-data-text="$t('no_data')"
                      return-object
                      dense
                      outlined
                    >
                      <template v-slot:item='{ item }'>
                        {{ item.SEARCH }}
                      </template>
                      <template v-slot:selection='{ item }'>
                        {{ item.PROVINCE_NAME }}
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols='12' md='6' lg='3'>
                    <v-autocomplete
                      v-model='address'
                      :items='locationList'
                      :no-data-text="$t('no_data')"
                      :label="$t('zip_code')"
                      item-text='SEARCH'
                      return-object
                      dense
                      outlined
                    >
                      <template v-slot:item='{ item }'>
                        {{ item.SEARCH }}
                      </template>
                      <template v-slot:selection='{ item }'>
                        {{ item.ZIPCODE }}
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols='12' md='2'>
                    <v-btn color='info' outlined @click="()=>{
                             typeAddNewAddress = 'customer'
                             isAddNewLocation = true
                           }">
                      {{ $t('add_new_address') }}
                    </v-btn>
                  </v-col>
                  <v-col cols='12' md='6'>
                    <v-autocomplete
                      v-model='customer_allergic'
                      chips
                      small-chips
                      multiple
                      :items='customerDrugList'
                      item-value='drug_id_pri'
                      item-text='drug_name'
                      :no-data-text="$t('no_data')"
                      :label="$t('be_allergic')"
                      outlined
                      dense
                    ></v-autocomplete>
                    <!-- <v-textarea
                      v-model="customer_allergic"
                      name="input-7-4"
                      outlined
                      dense
                      :label="$t('be_allergic')"
                      rows="2"
                    ></v-textarea> -->
                  </v-col>
                  <v-col cols='12' md='6'>
                    <v-textarea
                      v-model='customer_allergic2'
                      name='input-7-4'
                      outlined
                      dense
                      :label="`${$t('be_allergic')} ${$t('other')}`"
                      rows='2'
                    ></v-textarea>
                  </v-col>
                  <v-col cols='12' md='6'>
                    <v-textarea
                      v-model='customer_disease'
                      name='input-7-4'
                      outlined
                      dense
                      :label="$t('congenital_disease')"
                      rows='2'
                    ></v-textarea>
                  </v-col>
                  <v-col cols='12' md='6'>
                    <v-textarea
                      v-model='customer_comment'
                      name='input-7-4'
                      outlined
                      dense
                      :label="$t('note')"
                      rows='2'
                    ></v-textarea>
                  </v-col>
                  <v-col cols='12' md='6'>
                    <v-autocomplete
                      v-model='customer_tag_id'
                      chips
                      small-chips
                      multiple
                      :items='dataCustomerTagList'
                      item-value='customer_tag_id'
                      item-text='customer_tag_name'
                      :label="$t('customer_tag')"
                      outlined
                      dense
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols='12' md='6'>
                    <v-autocomplete
                      v-model='customer_group_id'
                      :items='dataCustomerGroupList'
                      item-value='customer_group_id'
                      item-text='customer_group_name'
                      :no-data-text="$t('no_information')"
                      :label="$t('group_customers')"
                      outlined
                      dense
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
                <h4 class='mt-3'>
                  <v-icon>{{ icons.mdiDomain }}</v-icon>
                  {{ $t('datacompany') }}
                </h4>
                <v-row>
                  <v-col cols='12' md='6' lg='3'>
                    <v-text-field v-model='company_name' outlined dense
                                  :label="$t('company_name')"></v-text-field>
                  </v-col>
                  <v-col cols='12' md='6' lg='3'>
                    <v-text-field v-model='company_tax' outlined dense
                                  :label="$t('tax_number')"></v-text-field>
                  </v-col>
                  <v-col cols='12' md='6' lg='3'>
                    <v-text-field
                      v-model='company_email'
                      outlined
                      dense
                      :label="$t('email')"
                      :rules='[emailValidator]'
                    ></v-text-field>
                  </v-col>
                  <v-col cols='12' md='6' lg='3'>
                    <v-text-field v-model='company_tel' outlined dense
                                  :label="$t('tel')"></v-text-field>
                  </v-col>
                  <v-col cols='12' md='6' lg='3'>
                    <v-text-field v-model='company_address' outlined dense
                                  :label="$t('address')"></v-text-field>
                  </v-col>
                  <v-col cols='12' md='6' lg='3'>
                    <v-autocomplete
                      v-model='localtionCompay'
                      :items='locationList'
                      item-text='SEARCH'
                      :label="$t('sub_district')"
                      return-object
                      :no-data-text="$t('no_data')"
                      dense
                      outlined
                    >
                      <template v-slot:item='{ item }'>
                        {{ item.SEARCH }}
                      </template>
                      <template v-slot:selection='{ item }'>
                        {{ item.SUB_DISTRICT_NAME }}
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols='12' md='6' lg='3'>
                    <v-autocomplete
                      v-model='localtionCompay'
                      :items='locationList'
                      item-text='SEARCH'
                      :label="$t('district')"
                      :no-data-text="$t('no_data')"
                      return-object
                      dense
                      outlined
                    >
                      <template v-slot:item='{ item }'>
                        {{ item.SEARCH }}
                      </template>
                      <template v-slot:selection='{ item }'>
                        {{ item.DISTRICT_NAME }}
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols='12' md='6' lg='3'>
                    <v-autocomplete
                      v-model='localtionCompay'
                      :items='locationList'
                      item-text='SEARCH'
                      :label="$t('province')"
                      :no-data-text="$t('no_data')"
                      return-object
                      dense
                      outlined
                    >
                      <template v-slot:item='{ item }'>
                        {{ item.SEARCH }}
                      </template>
                      <template v-slot:selection='{ item }'>
                        {{ item.PROVINCE_NAME }}
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols='12' md='6' lg='3'>
                    <v-autocomplete
                      v-model='localtionCompay'
                      :items='locationList'
                      :no-data-text="$t('no_data')"
                      :label="$t('zip_code')"
                      item-text='SEARCH'
                      return-object
                      dense
                      outlined
                    >
                      <template v-slot:item='{ item }'>
                        {{ item.SEARCH }}
                      </template>
                      <template v-slot:selection='{ item }'>
                        {{ item.ZIPCODE }}
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols='12' md='2'>
                    <v-btn color='info' outlined
                           @click="()=>{
                             typeAddNewAddress = 'company'
                             isAddNewLocation = true
                           }">
                      {{ $t('add_new_address') }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color='primary' type='submit' :loading='loading' :disabled='loading'>
              {{ $t('save') }}
            </v-btn>
            <v-btn color='secondary' outlined @click="$emit('update:is-add-new-customer', false)">
              {{ $t('cancel') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog ref='selectDate1' v-model='selectDate' :return-value.sync='customer_birthdate'
              width='290px'>
      <v-date-picker v-model='customer_birthdate' :locale='$i18n.locale' scrollable>
        <v-spacer></v-spacer>
        <v-btn text color='primary' @click='$refs.selectDate1.save(customer_birthdate)'>
          {{ $t('confirm') }}
        </v-btn>
        <v-btn text color='secondary' @click='selectDate = false'>
          {{ $t('cancel') }}
        </v-btn>
        <v-spacer></v-spacer>
      </v-date-picker>
    </v-dialog>
    <v-dialog v-model='isAddNewLocation' max-width='400' persistent>
      <v-card>
        <v-card-title> {{ $t('add_new_address') }}
                       ({{ typeAddNewAddress === 'company' ? $t('company') : $t('personal') }})
        </v-card-title>
        <v-form ref='formAddNewLocation' class='px-5' @submit.prevent='mapAddress'>
          <v-text-field
            v-model='customSubDistrict'
            :label="$t('sub_district')"
            :rules='[required]'
            outlined
            dense
          ></v-text-field>
          <v-text-field
            v-model='customDistrict'
            :label="$t('district')"
            :rules='[required]'
            outlined
            dense
          ></v-text-field>
          <v-text-field
            v-model='customProvince'
            dense
            :label="$t('province')"
            :rules='[required]'
            outlined
          ></v-text-field>
          <v-text-field
            v-model='customZipcode'
            :label="$t('zip_code')"
            :rules='[required]'
            outlined
            dense
          ></v-text-field>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn type='submit' color='primary'>
              {{ $t('save') }}
            </v-btn>
            <v-btn color='secodary' outlined @click='isAddNewLocation = false'>
              {{ $t('cancel') }}
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mdiCloudUploadOutline, mdiDomain, mdiClose } from '@mdi/js'
import { ref, watch } from '@vue/composition-api'
import { required, emailValidator, telValidator } from '@core/utils/validation'
import genders from '@/@fake-db/data/genders.json'
import prefix from '@/@fake-db/data/prefix.json'
import location_new from '@/@fake-db/data/location_new.json'
import bloodGroup from '@/@fake-db/data/bloodGroup.json'
import { sum_birthdate } from '@/plugins/filters'
import customerGroup from '@/api/customer/customerGroup'
import customerTag from '@/api/customer/customerTag'
import { i18n } from '@/plugins/i18n'
import customer from '@/api/customer/customer'
import validation from '@/api/validation'
import { socket } from '@/plugins/socket'
import document from '@/api/systemSetting/document'
import store from '@/store'
import router from '@/router'

export default {
  model: {
    prop: 'isAddNewCustomer',
    event: 'update:is-add-new-customer',
  },

  props: {
    isAddNewCustomer: {
      type: Boolean,
      default: false,
    },
    statusCheck: {
      type: String,
      default: 'add',
    },
  },

  setup(props, { emit }) {
    socket.on('inserted', async data => {
      if (store.state.app.status == 'add') {
        store.commit('app/ALERT', {
          message: 'card_detect',
          color: 'success',
        })
        image.value = 2
        checkCustomer(data)
      }
    })

    socket.on('removed', () => {
      if (store.state.app.status == 'add') {
        store.commit('app/ALERT', {
          message: 'card_not_detect',
          color: 'warning',
        })
        emit('alertMessage', '')
        clearData()
        image.value = 1
      }
    })

    const newCustomerId = ref('')
    const formAddNewLocation = ref(null)
    const isAddNewLocation = ref(false)
    const typeAddNewAddress = ref('customer')
    const formAddCustomer = ref(null)
    const customer_image = ref(null)
    const customer_idcard = ref('')
    const customer_prefix = ref('')
    const customer_gender = ref('')
    const customer_fname = ref('')
    const customer_lname = ref('')
    const customer_nname = ref('')
    const customer_birthdate = ref('')
    const customer_blood = ref('')
    const customer_age = ref('')
    const customer_email = ref('')
    const customer_tel = ref('')
    const customer_address = ref('')
    const customer_allergic = ref([])
    const customer_allergic2 = ref('')
    const customer_disease = ref('')
    const customer_comment = ref('')
    const customer_group_id = ref('')
    const customer_tag_id = ref([])
    const customer_image_card = ref('')
    const image = ref(1)
    const address = ref({
      SUB_DISTRICT_NAME: '',
      DISTRICT_NAME: '',
      PROVINCE_NAME: '',
      ZIPCODE: '',
    })

    // block compay
    const company_name = ref('')
    const company_tax = ref('')
    const company_tel = ref('')
    const company_email = ref('')
    const company_address = ref()
    const localtionCompay = ref({
      SUB_DISTRICT_NAME: '',
      DISTRICT_NAME: '',
      PROVINCE_NAME: '',
      ZIPCODE: '',
    })

    // add new address
    const customSubDistrict = ref('')
    const customDistrict = ref('')
    const customProvince = ref('')
    const customZipcode = ref('')

    const selectDate = ref(false)
    const genderList = ref(genders.data)
    const prefixList = ref(prefix.data)
    const bloodTypeList = ref(bloodGroup.data)
    const locationList = ref(location_new)
    const blob_image = ref(null)
    const dataCustomerGroupList = ref([])
    const dataCustomerTagList = ref([])
    const customerDrugList = ref([])

    // alert valiable

    const loading = ref(false)

    const { customerGroupList } = customerGroup
    const { customerTagList } = customerTag
    const { customerAdd, customerUpdate } = customer
    const { documentSetting } = document
    const { checkCustomerAdd, checkCustomerIdcardAndFirstname } = validation

    const bodyCustomerGroup = {
      searchtext: '',
      lang: i18n.locale,
      active_page: 1,
      per_page: '0',
    }

    const checkCustomer = customer => {
      checkCustomerIdcardAndFirstname({
        customer_idcard: customer.citizen_code,
        customer_fname: customer.th_name.firstname,
      }).then(res => {
        if (res.response) {
          emit('alertMessage', '')
          if (res.data.length == 1) {
            mapDataCardReader(customer, 1, res.data[0])
          } else {
            getCustomerGroup()
            mapDataCardReader(customer, 2, res.data)
          }
        } else {
          emit(
            'alertMessage',
            i18n.t('message_then_more'),
            `${customer.th_name.firstname} ${customer.th_name.lastname}`,
          )
        }
      })
    }

    const getDocSetting = () => {
      documentSetting().then(res => {
        newCustomerId.value = res.customer
      })
    }

    const getCustomerTag = () => {
      customerTagList(bodyCustomerTag).then(res => {
        const { data } = res
        dataCustomerTagList.value = data
      })
    }

    const getCustomerGroup = () => {
      customerGroupList(bodyCustomerGroup).then(res => {
        const { data } = res
        dataCustomerGroupList.value = data
        customer_group_id.value = data[0].customer_group_id
      })
    }

    const bodyCustomerTag = {
      searchtext: '',
      active_page: 1,
      per_page: '0',
    }

    const updateCustomer = customer => {
      const formData = new FormData()
      formData.append('customer_id_pri', customer.customer_id_pri)
      formData.append('customer_idcard', customer_idcard.value)
      formData.append('customer_group_id', customer.customer_group_id)
      formData.append('customer_prefix', customer_prefix.value)
      formData.append('customer_fname', customer_fname.value)
      formData.append('customer_lname', customer_lname.value)
      formData.append('customer_nname', customer.customer_nname)
      formData.append('customer_gender', customer_gender.value)
      formData.append('customer_blood', customer.customer_blood)
      formData.append('customer_email', customer.customer_email)
      formData.append('customer_tel', customer.customer_tel)
      formData.append('customer_birthdate', customer_birthdate.value)
      formData.append('customer_age', customer_age.value)
      formData.append('customer_address', customer_address.value)
      formData.append('customer_district', address.value.SUB_DISTRICT_NAME)
      formData.append('customer_amphoe', address.value.DISTRICT_NAME)
      formData.append('customer_province', address.value.PROVINCE_NAME)
      formData.append('customer_zipcode', address.value.ZIPCODE)
      formData.append('customer_allergic', customer.customer_allergic)
      formData.append('customer_disease', customer.customer_disease)
      formData.append('customer_comment', customer.customer_comment)
      formData.append('customer_point', customer.customer_point)
      formData.append('customer_point_change', '')
      formData.append('company_name', customer.company_name)
      formData.append('company_tax', customer.company_tax)
      formData.append('company_tel', customer.company_tel)
      formData.append('company_email', customer.company_email)
      formData.append('company_address', customer.company_address)
      formData.append('company_district', customer.company_district)
      formData.append('company_amphoe', customer.company_amphoe)
      formData.append('company_province', customer.company_province)
      formData.append('company_zipcode', customer.company_zipcode)
      formData.append('customer_tag', customer.customer_tag)
      formData.append('image', image.value)
      formData.append('customer_image', '')
      formData.append('customer_image_card', customer_image_card.value)
      customerUpdate(formData).then(res => {
        if (res.response) {
          store.commit('app/ALERT', {
            message: 'update_data_successfully',
            color: 'success',
          })
          router.push({
            name: 'DetailCustomer',
            params: {
              id: customer.customer_id_pri,
            },
          }).catch(err => {
            console.log(err)
          })
        } else {
          store.commit('app/ALERT', {
            message: res.message,
            color: 'error',
          })
        }
      })
    }

    const previewFiles = event => {
      customer_image.value = event.target.files[event.target.files.length - 1]
      blob_image.value = URL.createObjectURL(event.target.files[event.target.files.length - 1])
    }

    const mapGender = prefix => {
      if (prefix == 'นาย' || prefix == 'ด.ช.') {
        customer_gender.value = genderList.value[0].name
      } else if (prefix == 'นาง' || prefix == 'นางสาว' || prefix == 'ด.ญ.' || prefix == 'น.ส.') {
        customer_gender.value = genderList.value[1].name
      } else if (prefix == 'Mr.' || prefix == 'Master') {
        customer_gender.value = genderList.value[2].name
      } else if (prefix == 'Mrs.' || prefix == 'Miss') {
        customer_gender.value = genderList.value[3].name
      } else {
        customer_gender.value = genderList.value[4].name
      }
    }

    const ageCalculate = birthday => {
      customer_age.value = sum_birthdate(birthday)
    }

    const mapAddress = () => {
      const isInvalidForm = formAddNewLocation.value.validate()
      if (!isInvalidForm) return
      locationList.value.push({
        SEARCH: `${customSubDistrict.value} > ${customDistrict.value} > ${customProvince.value} > ${customZipcode.value}`,
        PROVINCE_NAME: customProvince.value,
        DISTRICT_NAME: customDistrict.value,
        SUB_DISTRICT_NAME: customSubDistrict.value,
        ZIPCODE: customZipcode.value,
      })
      if (typeAddNewAddress.value === 'company') {
        localtionCompay.value = {
          SEARCH: `${customSubDistrict.value} > ${customDistrict.value} > ${customProvince.value} > ${customZipcode.value}`,
          PROVINCE_NAME: customProvince.value,
          DISTRICT_NAME: customDistrict.value,
          SUB_DISTRICT_NAME: customSubDistrict.value,
          ZIPCODE: customZipcode.value,
        }
      } else {
        address.value = {
          SEARCH: `${customSubDistrict.value} > ${customDistrict.value} > ${customProvince.value} > ${customZipcode.value}`,
          PROVINCE_NAME: customProvince.value,
          DISTRICT_NAME: customDistrict.value,
          SUB_DISTRICT_NAME: customSubDistrict.value,
          ZIPCODE: customZipcode.value,
        }
      }

      isAddNewLocation.value = false
      customSubDistrict.value = ''
      customDistrict.value = ''
      customProvince.value = ''
      customZipcode.value = ''
    }
    const newCustomer = () => {
      const isInvalidForm = formAddCustomer.value.validate()
      if (!isInvalidForm) return
      loading.value = true
      const body = {
        customer_fname: customer_fname.value,
        customer_lname: customer_lname.value,
        customer_idcard: customer_idcard.value,
      }
      checkCustomerAdd(body).then(res => {
        if (res.status == 1) {
          store.commit('app/ALERT', {
            message: 'name_idcard_already',
            color: 'error',
          })
          loading.value = false
        } else if (res.status == 2) {
          store.commit('app/ALERT', {
            message: 'name_already',
            color: 'error',
          })
          loading.value = false
        } else if (res.status == 3) {
          store.commit('app/ALERT', {
            message: 'idcard_already',
            color: 'error',
          })
          loading.value = false
        } else {
          const formData = new FormData()
          formData.append('customer_idcard', customer_idcard.value)
          formData.append('customer_group_id', customer_group_id.value)
          formData.append('customer_prefix', customer_prefix.value == 'ไม่ระบุ' ? '' : customer_prefix.value)
          formData.append('customer_fname', customer_fname.value)
          formData.append('customer_lname', customer_lname.value)
          formData.append('customer_nname', customer_nname.value)
          formData.append('customer_gender', customer_gender.value == 'ไม่ระบุ' ? '' : customer_gender.value)
          formData.append('customer_blood', customer_blood.value)
          formData.append('customer_email', customer_email.value)
          formData.append('customer_tel', customer_tel.value)
          formData.append('customer_birthdate', customer_birthdate.value)
          formData.append('customer_address', customer_address.value)
          formData.append('customer_district', address.value.SUB_DISTRICT_NAME)
          formData.append('customer_amphoe', address.value.DISTRICT_NAME)
          formData.append('customer_province', address.value.PROVINCE_NAME)
          formData.append('customer_zipcode', address.value.ZIPCODE)
          formData.append('customer_allergic', customer_allergic2.value)
          formData.append('customer_disease', customer_disease.value)
          formData.append('customer_comment', customer_comment.value)
          formData.append('company_name', company_name.value)
          formData.append('company_tax', company_tax.value)
          formData.append('company_tel', company_tel.value)
          formData.append('company_email', company_email.value)
          formData.append('company_address', company_address.value)
          formData.append('company_district', localtionCompay.value.SUB_DISTRICT_NAME)
          formData.append('company_amphoe', localtionCompay.value.DISTRICT_NAME)
          formData.append('company_province', localtionCompay.value.PROVINCE_NAME)
          formData.append('company_zipcode', localtionCompay.value.ZIPCODE)

          if (customer_allergic.value.length > 0) {
            customer_allergic.value.forEach((item, index) => {
              formData.append(`drug_id_pri[${index}]`, item)
            })
          }

          if (customer_tag_id.value.length > 0) {
            customer_tag_id.value.forEach((item, index) => {
              formData.append(`customer_tag_id[${index}]`, item)
            })
          }

          formData.append('image', image.value)
          formData.append('customer_image', customer_image.value)
          formData.append('customer_image_card', customer_image_card.value)
          customerAdd(formData).then(res => {
            store.commit('app/ALERT', {
              message: res.message,
              color: res.response ? 'success' : 'error',
            })
            emit('update:is-add-new-customer', false)
            loading.value = false
            clearData()
            emit('onAdd', res.response)
          })
        }
      })
    }
    const clearData = () => {
      customer_idcard.value = ''
      customer_prefix.value = ''
      customer_gender.value = ''
      customer_fname.value = ''
      customer_lname.value = ''
      customer_nname.value = ''
      customer_birthdate.value = ''
      customer_age.value = ''
      customer_blood.value = ''
      customer_email.value = ''
      customer_tel.value = ''
      customer_address.value = ''
      address.value = {
        SUB_DISTRICT_NAME: '',
        DISTRICT_NAME: '',
        PROVINCE_NAME: '',
        ZIPCODE: '',
      }
      customer_allergic.value = ''
      customer_disease.value = ''
      customer_comment.value = ''
      customer_group_id.value = ''
      customer_tag_id.value = ''
      company_name.value = ''
      company_tax.value = ''
      company_email.value = ''
      company_tel.value = ''
      company_address.value = ''
      localtionCompay.value = {}
      blob_image.value = null
    }
    const mapDataCardReader = (data, status, customer) => {
      customer_prefix.value = data.th_name.prefix == 'น.ส.' ? 'นางสาว' : data.th_name.prefix
      customer_fname.value = data.th_name.firstname
      customer_lname.value = data.th_name.lastname
      customer_idcard.value = data.citizen_code
      customer_image_card.value = data.photo
      blob_image.value = data.photo
      customer_birthdate.value = `${parseInt(data.dob.year) - 543}-${data.dob.month}-${data.dob.day}`
      replaceAddress(data.address, status, customer)
    }
    const replaceAddress = (dataAddress, status, customer) => {
      const address_2 = dataAddress.includes('ตำบล') ? dataAddress.split('ตำบล') : dataAddress.split('แขวง')
      const address_3 = address_2[1].split(' ')
      const tumbon = address_3[0]
      const distritc = address_3[1].replace('อำเภอ', '').replace('เขต', '')
      const province = address_3[2].replace('จังหวัด', '')
      const ZipCode = locationList.value.find(item => item.DISTRICT_NAME == distritc)
      customer_address.value = address_2[0].trim()
      address.value = {
        SUB_DISTRICT_NAME: tumbon,
        DISTRICT_NAME: distritc,
        PROVINCE_NAME: province,
        ZIPCODE: ZipCode.ZIPCODE,
        SEARCH: `${tumbon} > ${distritc} > ${province} > ${ZipCode.ZIPCODE}`,
      }
      if (status == 2) {
        emit('update:is-add-new-customer', true)
      } else {
        updateCustomer(customer)
      }
    }
    watch([customer_prefix, customer_birthdate], (newvalue, oldvalue) => {
      if (newvalue[0] !== oldvalue[0]) {
        mapGender(newvalue[0])
      }
      if (newvalue[1] !== oldvalue[1]) {
        ageCalculate(newvalue[1])
      }
    })

    watch(
      () => props.isAddNewCustomer,
      newvalue => {
        if (newvalue) {
          customer.customerDrugList({
            searchtext: '',
            active_page: '1',
            per_page: '0',
          }).then(res => {
            customerDrugList.value = res.data
          })
          getCustomerGroup()
          getCustomerTag()
          getDocSetting()
        }
        if (!newvalue) {
          store.commit('app/statusInputCard', {
            status: 'add',
          })
        }
      },
    )

    return {
      newCustomerId,
      customer_idcard,
      customer_prefix,
      customer_gender,
      customer_fname,
      customer_lname,
      customer_nname,
      customer_birthdate,
      customer_age,
      customer_blood,
      customer_email,
      customer_tel,
      customer_address,
      address,
      customer_allergic,
      customer_disease,
      customer_comment,
      customer_group_id,
      customer_tag_id,
      company_name,
      company_tax,
      company_email,
      company_tel,
      company_address,
      localtionCompay,
      bloodTypeList,
      locationList,
      selectDate,
      formAddCustomer,
      formAddNewLocation,
      blob_image,
      previewFiles,
      required,
      telValidator,
      emailValidator,
      prefixList,
      newCustomer,
      dataCustomerGroupList,
      dataCustomerTagList,
      isAddNewLocation,
      customSubDistrict,
      customDistrict,
      customProvince,
      customer_allergic2,
      customZipcode,
      mapAddress,
      image,
      customerDrugList,
      typeAddNewAddress,
      loading,
      icons: {
        mdiCloudUploadOutline,
        mdiDomain,
        mdiClose,
      },
    }
  },
}
</script>
